@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-balham.css';
@import '~ag-grid-community/dist/styles/ag-theme-balham-dark.css';

.stats-page {
    overflow: hidden;
}

.stats-page button svg {
    margin-bottom: -0.15em;
}

.data-table-cont {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header-btns > * {
    margin-right: 8px;
}

.header-btns i svg {
    margin: auto;
}

.ant-tag.detail-tag { 
    border-style: dashed;
}

.fullscreen {
    z-index: 9999;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.ant-btn.exit-fullscreen-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    padding: 5px;
    opacity: 0.8;
    color: #e86C4d;
}

.ag-grid-container {
    flex: 1;
}

/* enable basic browser text selection in grid cells */
div.ag-root .ag-cell-focus {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

@media only screen and (max-width: 600px) {
    .ant-btn.exit-fullscreen-button svg {
        height: 40px;
        width: 40px;
        size: 40px;
    }
}

.ag-cell a {
    color: #0000f2;
}

@media (prefers-color-scheme: dark) {
    .ag-cell a {
        color: #00b6f2;
    }

    .status-cell a {
        color: #0000f2;
    }

    .ant-tag.detail-tag { 
        background: rgba(100, 100, 100, 0.2);
        color: #ddd;
    }
}
