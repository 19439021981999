@import '~antd/dist/antd.css';

.nav-menu a {
    color: rgb(235, 235, 236);
}

.ant-menu-inline-collapsed span.menu-title {
  display: none;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0px 18px !important;
}

.ant-menu-inline-collapsed {
  width: 50px;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: rgba(50,52,54,0.5);
}
