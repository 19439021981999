.chart-container {
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.chart-header-container {
    background-color: #eee;
    height: 40px;
}

.chart-header-form {
    display: flex;
    padding: 5px 0px;
    
}

.chart-header-form > * {
    margin: 0px 5px;
}

.chart-header-form > .chart-variables {
    flex: 1;
    display: flex;
}

.chart-body {
    background-color: #fff;
    height: 200px;
}

 .chart-sm .chart-body {
    height: 100px;
}

.chart-md .chart-body {
    height: 200px;
}

.chart-lg .chart-body {
    height: 400px;
}
