.graph-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.multi-graph-page {
    overflow: auto;
}

.graph-page .page-body {
    display: flex;
    flex-direction: column;
}

.add-graph {
    border-color: blue;
    border-radius: 2px;
    background-color: #cccccc;
    padding: 5px;
}

.add-graph .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0px;
}

.graph-list>* {
    margin: 10px 0px;
}

.graph-list :first-child.chart-container {
    margin: 0px 0px;
}

.graph-list {
    padding-top: 1px;
    overflow: auto;
}

.chart-container {
    flex: 1;
}

.flex-input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sensor-capabilities-list {
    display: flex;
    flex-direction: column;
    width: 76%;
    gap: 0.5vh;
    justify-content: space-between;
    max-height: 10vh;
    overflow-y: scroll;
    padding-right: 5px;  
    scrollbar-width: thin;
}

.graph-page .ant-page-header {
    padding: 10px;
}

.sensor-capabilities-list .sensor-capabilities {
    display: flex;
    flex-direction: row;
    gap: 0.5vw;
    justify-content: space-between;
}

.graph-page-sensor-select-clear {
    align-content: center;
    cursor: pointer;
}

.graph-date-range {
    width: 24%;
    display: flex;
    flex-direction: column;
}

.flex-input-group>*:not(:first-child) {
    margin-left: 10px;
}

.sensor-note-plotline-label-title {
    cursor: pointer;
    background: black;
    padding: 3px;
    color: white;
    border-radius: 3px;
    text-transform: capitalize;
}

.sensor-note-modal .ant-modal-title {
    text-transform: capitalize;
}

.date-range-shortcuts {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-top: 5px;
}

.sensor-note-plotline-label .tooltip {
    display: none;
    position: unset;
    z-index: 999;
    padding: 5px;
}

.sensor-note-plotline-label .tooltip-inner {
    background: white;
    color: black;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
}

.sensor-note-plotline-label-title:hover+.tooltip {
    display: block;
}

.sensor-note-plotline-label .tooltip:hover {
    display: block;
}

.highcharts-plot-line-label {
    overflow: visible !important;
}

.highcharts-plot-line-label:hover {
    z-index: 1;
}


@media (prefers-color-scheme: dark) {
    .graph-page #sensorSelect .ant-select-selection-placeholder,
    .graph-page #sensorSelect .ant-list-item-meta-title,
    .graph-page #sensorSelect li.ant-list-item,
    .graph-page #sensorSelect .anticon {
        color: black;
    }
}
