.ant-transfer-list-body {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.ant-transfer-list-body-search-wrapper {
    position: relative;
}

.ant-transfer-list-body-customize-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: auto;
}

.ant-table-wrapper {
    flex: 1;
}

@media only screen and (max-width: 600px) {
    .ant-transfer {
        flex-direction: column;
    }

    .ant-transfer-operation {
        transform: rotate(90deg);
    }
}
