.block-detail-page {
  padding: 20px;
}

.block-page-loader {
  font-size: 24px;
}

.block-detail-display-single {
  display: flex;
  gap: 20px;
  font-size: 16px;
  padding: 5px;
}
