.results {
    flex: 1 1;
    overflow-y: auto;
    padding-left: 35px;
    padding-right: 35px;
}

.results .ant-pagination {
    padding-bottom: 20px;
}

.resultsHeader {
    display: flex;
    flex-direction: 'row';
    justify-content: center;
    padding-left: 35px;
    padding-right: 35px;
}
