@import '~antd/dist/antd.css';
@import '~farmx-web-ui/dist/index.css';
/* override ant changes from 3.x to 4.x */

.ant-btn {
  padding: 0 15px;
}

html, body, #root {
  width: 100%;
  height: 100%;
  min-height: -webkit-fill-available;
}

#root {
  display: flex;
}

body {
  margin: 0;
}

.main-menu a {
  color: white;
}

*:focus {
  /*
  https://www.eventbrite.com/engineering/how-to-fix-the-ugly-focus-ring-and-not-break-accessibility-in-react/
  outline: 2px solid #4d90fe;
  */
  outline: none;
}

.layout {
  flex: 1 1;
  overflow: hidden;
}

.content-container {
  padding: '0 0px';
  display: flex;
  flex: 1;
  overflow: hidden;
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.layout header {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  background-color: rgb(32, 33, 36);
  border-bottom: 1px solid #333;
}

.layout .main-menu {
  line-height: 50px;
}

.layout .logo {
  padding: 0px 15px 0px 15px;
}

.header-logo {
  vertical-align: middle;
  height: 30px;
}

.message-container {
  flex-direction: column;
  border-radius: 5px;
  background-color: #fff;
  padding: 2em;
  margin: 2em 10%;
  flex-grow: 1;
}

.padded {
  padding: 0px 24px;
}

.padded-top {
  padding-top: 16px;
}

.page-body {
  overflow: auto;
}

.page-body>* {
  margin: 10px 0px;
}

.ant-menu-submenu-title span svg, a svg {
  margin-bottom: -2px;
  margin-right: 10px;
}

.fa-spin svg {
  animation: fa-spin 2s infinite linear;
}

.ant-select-dropdown .rc-virtual-list-scrollbar {
  display: block !important;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@media only screen and (max-width: 600px) {
  .content-container {
    padding: '0 0px';
  }

  .message-container {
    margin: 0em 0em;
    flex: 1 0;
    border-radius: 0px;
  }

  .padded {
    padding: 0px 5px;
  }

  .page-body>* {
    margin: 5px 0px;
  }
}

@media (prefers-color-scheme: dark) {
  html, body, #root {
    background-color: rgb(32, 33, 36);
    color: rgba(250, 250, 250, 1);
  }

  h1, h2, h3, h4, h5, h6 {
    color: rgba(250, 250, 250, 0.85);
  }

  .ant-layout {
    background-color: rgb(53, 54, 58);
  }

  .message-container {
    background-color: rgb(64, 66, 72);
    color: rgb(235, 235, 236);
  }

  a {
    color: #74bcff;
  }

  .ant-page-header-heading-title {
    color: rgba(235, 235, 236, 0.85);
  }

  .ant-page-header {
    color: rgba(250, 250, 250, 0.85);
  }

  .map-page-sidebar-tabs {
    overflow-y: scroll;
  }
}

@import './app-dark.css';
