.soil-demo-container {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    color: white;
    font-weight: bold;

    td {
        transition: background-color 1s;
    }
}

.soil-data-table-latest {
    width: 30vmin;

    .data-label {
        color: white;
        font-weight: bold;
        font-size: 4vmin;
        text-align: right;
        padding-right: 1em;
    }

    .depth-label {
        color: white;
        font-weight: bold;
        font-size: 3vmin;
        text-align: left;
        padding-right: 1em;
        padding-bottom: 0.5vmin;
    }
}

.labels {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%; 
}

.soil-data-table {
    flex: 1 1;
}

.time-label {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    font-size: 1.5vmin;
}

.sensor-label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    font-size: 1.5vmin;
}

.demo-page-loader {
    margin: auto;
    font-size: 4vmin;
}