.detail-page {
    overflow-y: auto;
}

.detail-page-content {
    padding: 5px 40px;
}

.detail-buttons > * {
    margin-right: 8px;
}
