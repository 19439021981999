
/* @import '~mapbox-gl/dist/mapbox-gl.css'; */

.map-parent {
    flex: 1 1;
    overflow: hidden;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

.popup-title {
    font-weight: bold;
    text-transform: capitalize;
}

.popup-location {
    font-size: 0.8em;
}

.scale-container {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
}

.top-controls {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    z-index: 2000;
}
