.plans-edit-list .ant-collapse-arrow {
  top: 23px !important;
}

.flex-grid {
  display: flex;
  align-items: baseline;
}

.col {
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

@media (max-width: 500px) {
  .flex-grid {
    display: block;
  }
}
